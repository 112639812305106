<template>
<div class="offcanvas offcanvas-start" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
          <button type="button" ref='Btn' class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <div class="offcanvas-header__logo">
            <img :src="require('../assets/logo.png')" />
        </div>
    </div>
 
    <div class="offcanvas-body">
        <div class="mobileMenu">
            <div class="mobileMenu__contacts">
                <a href="tel:+79281296165" class="mb-2">+7 (928) 129-61-65</a>
                <a href="tel:++79281303277" class="mb-2">+7 (928) 130-32-77</a>
                <a href="mailto: zakaz@eli-mleli.ru">zakaz@eli-mleli.ru</a>
            </div>
            <div class="mobileMenu__links">
                <span @click="hideMenu('/')">Меню</span>
                <router-link to="#">Политика конфиденциальности и оферта </router-link>
                <router-link to="#">Пользовательское соглашение </router-link>
            </div>
           
        </div>
         <div class="offcanvas-body__btn">
                <button v-if="!$store.state.auth.token_status" data-bs-toggle="modal" data-bs-target="#login" @click='closeMenu()' class="btn-oval">Войти</button>
            </div>
    </div>
</div>
</template>
<script>
export default {
            methods: {
        hideMenu(path) {
              const elem = this.$refs.Btn
            elem.click()
            this.$router.push(path)
           // this.$store.commit('mobMenu');
        },
        closeMenu(){
           const elem = this.$refs.Btn
            elem.click()
        }
  
    },
}
</script>
<style lang="scss" scoped>
.offcanvas {
    max-width: 329px !important;
    z-index: 9999 !important;
}

.offcanvas-body {
    padding-left: 0;
    padding-right: 0;
     background: url(../assets/leaf.svg) 55% 67% no-repeat, url(../assets/bgMobleMenu.svg) 90% 87% no-repeat;
}

.offcanvas-header .btn-close {
    position: absolute;
}

.offcanvas-header__logo {
    margin-left: 40px;

    img {
        width: 94px;
        height: auto;
    }
}

.mobileMenu {
    position: relative;

    &__contacts {
        padding-top: 16px;
        padding-bottom: 16px;

        padding-left: 20px;
        padding-right: 20px;
        background: url(../assets/garlicOrange.svg) 90% 50% no-repeat $orange;

        a {
            text-decoration: none;
            display: block;
            font-size: 16px;
            line-height: 107.19%;
            color: #fff;
        }
    }

    &__links {
        a,span {
            cursor: pointer;
            display: block;
            font-family: Montserrat;
            border: 1px solid #EDEDED;
            font-weight: 500;
            font-size: 16px;
            line-height: 107.19%;
            text-decoration: none;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            color: #323232;
        }
    }

    &__btn {
        position: absolute;
        bottom: 0;
    }
}


.offcanvas-body__btn{
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 33px;
    .btn-oval {
        width: 289px;
    }
}
.text-reset{
 width: 19px ;
 height: 19px;
position: absolute;
left: 20px;
   background: url(../assets/close.svg) 50% 50% no-repeat #fff;
   border: none;
}
</style>
