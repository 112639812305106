import axios from "axios";
import {BASE_URL} from './../consts';
export default {
  state: {
    info:{},
    orders:{},
    userFilter:{},
    autoorder:{},
    bells:{
      orders:[]
    },
    balance:1
  },
  mutations: {
    SET_INFO(state, payload) {
      state.info = payload.info;
    },
    SET_ORDER_LIST(state, payload) {
      state.orders = payload.orders;
    },
    SET_USER_FILTER(state, payload) {
      state.userFilter = payload.userFilter;
    },
    SET_AUTOORDER(state, payload) {
      state.autoorder = payload.autoorder;
    },
    SET_BELL(state, payload) {
      state.bells = payload.bells;
    },
    SET_BALANCE(state, payload) {
      state.balance = payload.balance;
    },
  },

  actions: {
    //INFO////////////////////////////////////////////////////////////////
    async actionGetInfo({ commit }) {
      const res = await axios({
        url: `${BASE_URL}/personal/info`,
      });
      console.log('ssss',res)
      commit("SET_INFO", {
         info: res.data,
      });
    },
    async actionUpdateInfo(_, data) {
        await axios({
          url: `${BASE_URL}/personal/info/update`,
          data: data,
          method: "POST",
        });
      },
       //ORDER HISTORY////////////////////////////////////////////////////////////////
      async  actionGetOrderList({ commit }) {
        const res = await axios({
          url: `${BASE_URL}/personal/order/list`,
        });
        commit("SET_ORDER_LIST", {
          orders: res.data,
       });
      },
      async actionUpdateStars(_, data) {
         await axios({
          url: `${BASE_URL}/personal/order/setstars`,
          data: data,
          method: "POST",
        });
      },

      async actionCancelOrder(_, data) {
        await axios({
         url: `${BASE_URL}/personal/order/cancel`,
         data: data,
         method: "POST",
       });
     },
     //USER FILTER////////////////////////////////////////////////////////////////
     async  actionGetUserFilter({ commit }) {
      const res = await axios({
        url: `${BASE_URL}/personal/filters`,
      });
      commit("SET_USER_FILTER", {
        userFilter: res.data,
     });
    },

    async actionUpdateUserFilter(_, data) {
      try {
        await axios({
          url: `${BASE_URL}/personal/filters/update`,
          data: data,
          method: "POST",
        });
      } catch (error) {
        alert(error)
      }
 
   },
     //AUTOORDER////////////////////////////////////////////////////////////////
  async  actionGetAutoorder({ commit }) {
    const res = await axios({
      url: `${BASE_URL}/personal/autoorder/list`,
    });
    commit("SET_AUTOORDER", {
      autoorder: res.data,
   });
  },
  async actionAutogenerateMenu(_, data) {
    try {
      await axios({
        url: `${BASE_URL}/personal/autoorder/generate`,
        data: data,
        method: "POST",
        
      });
    } catch (error) {
      alert(error)
    }

 },
 async actionGetBell({ commit }) {
  try {
    const res = await axios({
      url: `${BASE_URL}/personal/alert/list`,
    });
    commit("SET_BELL", {
      bells: res.data,
   });
  } catch (error) {
    console.log(error)
  }

},
async actionGetBalance({ commit }) {
  try {
    const res = await axios({
      url: `${BASE_URL}/personal/balance/get`,
    });
    commit("SET_BALANCE", {
      balance: res.data,
   });
  } catch (error) {
    console.log(error)
  }

},
async actionAddBalance(_,sum) {
  try {
    const res = await axios({
      url: `${BASE_URL}/personal/balance/add?price=${sum.sum}`,
      method: "POST",
    });
  return res;
  } catch (error) {
    alert(error)
  }

},
  },

 
  
//   getters: {
//     isLoggedIn: (state) => !!state.token,
//     authStatus: (state) => state.status,
//   },
};
