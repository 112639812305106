import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: {auth:false},
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/edit-cart/:id',
    name: 'CartEdit',
    meta: {auth:true},
    component: () => import('../views/CartEdit.vue')
  },
  // {
  //   path: '/contacts',
  //   name: 'Contacts',
  //   component: () => import('../views/Contacts.vue')
  // },
  // {
  //   path: '/inner-bill',
  //   name: 'InnerBill',
  //   component: () => import('../views/InnerBill.vue')
  // },
  // {
  //   path: '/filter-settings',
  //   name: 'FilterSettings',
  //   component: () => import('../views/FilterSettings.vue')
  // },
  // {
  //   path: '/auto-order',
  //   name: 'AutoOrder',
  //   component: () => import('../views/AutoOrder.vue')
  // },
  // {
  //   path: '/order-history',
  //   name: 'OrderHistory',
  //   component: () => import('../views/OrderHistory.vue')
  // },

  //   {
  //   path: '/admin-history',
  //   name: 'AdminHistory',
  //   component: () => import('../views/AdminHistory.vue') 
  //   // +
  // },
  // {
  //   path: '/admin-menu',
  //   name: 'AdminMenu',
  //   component: () => import('../views/AdminMenu.vue')
  // },
  // {
  //   path: '/admin-registry',
  //   name: 'AdminRegistry',
  //   component: () => import('../views/AdminRegistry.vue')
  // },
  // {
  //   path: '/admin-days',
  //   name: 'AdminDays',
  //   component: () => import('../views/AdminDays.vue')
  // },
  {
    path: '/cabinet', 
    meta: {auth:true},
    component: () => import('../views/Cabinet.vue'),
    children: [
      {
        path: 'contacts',
        meta: {auth:true, title: 'Контактная информация'},
        component: () => import('../views/CabinetContacts.vue'),
      },
      {
        path: 'history',
        meta: {auth:true, title: 'История заказов'},
        component: () => import('../views/CabinetHistory.vue'),
      },
      { 
        path: 'bill',
        meta: {auth:true, title:'Внутренний счет'},
        component: () => import('../views/CabinetBill.vue'),
      },
      {
        path: 'filter',
        meta: {auth:true,title:'Настройка фильтров'},
        component: () => import('../views/CabintetFilter.vue'),
      },
      {
        path: 'auto',
        meta: {auth:true,title:'Автозаказ'},
        component: () => import('../views/CabinetAuto.vue'),
      }
    ]
  },
  {
    path: '/admin', 
    meta: {auth:true},
    component: () => import('../views/Operator.vue'),
    children: [
      {
        path: 'preparation',
        meta: {auth:true},
        component: () => import('../views/OperatorPreparation.vue'),
        children: [
          {
            path: 'assembly',
            meta: {auth:true},
            component: () => import('../views/OperatorPreparationAssembly.vue'),
          },
          {
            path: 'assembled',
            meta: {auth:true},
            component: () => import('../views/OperatorPreparationAssembled.vue'),
          },
          {
            path: 'History',
            meta: {auth:true},
            component: () => import('../views/OperatorPreparationHistory.vue'),
          },
        ]
      },
      {
        path: 'menu',
        meta: {auth:true},
        component: () => import('../views/OperatorMenu.vue'),
      },
      {
        path: 'registry',
        meta: {auth:true},
        component: () => import('../views/OperatorRegistry.vue'),
      },
      {
        path: 'days',
        meta: {auth:true},
        component: () => import('../views/OperatorDays.vue'),
      },
      // {
      //   path: 'assembly',
      //   component: () => import('../views/OperatorAssembly.vue'),
      // },
      // {
      //   path: 'assembled',
      //   component: () => import('../views/OperatorAssembled.vue'),
      // },
      // {
      //   path: 'history',
      //   component: () => import('../views/OperatorHistory.vue'),
      // },

    ]
  },
  {
    path: '/operator',
    name: 'AdminOrders',
    meta: {auth:true},
    component: () => import('../views/AdminOrders.vue'),
    children: [
      {
        path: 'assembly',
        meta: {auth:true},
        component: () => import('../views/OperatorAssembly.vue'),
      },
      {
        path: 'assembled',
        meta: {auth:true},
        component: () => import('../views/OperatorAssembled.vue'),
      },
      {
        path: 'history',
        meta: {auth:true},
        component: () => import('../views/OperatorHistory.vue'),
      },

    ]
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {auth:false},
    component: () => import('../views/Orders.vue'),
  },
  {
    path: '/print',
    name: 'Print',
    meta: {auth:false},
    component: () => import('../views/Print.vue'),
  }
]



const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  base: '/',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.auth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    } else {
      next('/') 
    }
    
  } else {
    next() 
  }
})

export default router
