<template>
<div class="top-navbar-wrapper">
    <div class="top-navbar mt-3">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-5 d-none d-md-block">
                <div class="top-navbar__meta">
                    <a href="tel:+79281296165">+7 (928) 129-61-65</a>
                    <span class="me-5"> С 9 до 18 (Пн-Пт)</span>
                    <a href="mailto:zakaz@eli-mleli.ru">zakaz@eli-mleli.ru</a>
                </div>
            </div>
            <div class="col-6 col-md-2 d-flex d-md-block align-items-center">
                <div class="top-navbar__burger d-md-none" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                    <img :src="require('../assets/burger.svg')" />
                </div>
                <div class="top-navbar__logo">
                    <router-link to="/"><img :src="require('../assets/logo.png')" /></router-link>
                </div>
            </div>
            <div class="col-6 col-md-5 d-flex justify-content-end" v-if="hideIfAdmin()">
                <div class="top-navbar__btns" v-if="this.$route.path=='/'">
                    <router-link :to="(countOfGoods>0)?'/cart':''" class="btn-round me-2 me-md-0"><img :src="require('../assets/case.svg')" /><span class="cart-count">{{countOfGoods}}</span></router-link>
                    <span v-if="$store.state.auth.token_status" class="btn-round btn-round--grey me-2 me-md-0 btn-dark-round d-md-none" @click="showMobMenu()"><img :src="require('../assets/cabMob.svg')" /></span>
                    <router-link v-if="$store.state.auth.token_status" to="/cabinet/contacts" class="btn-oval-low ms-4 me-2 d-none d-md-inline-block">{{($store.state.cabinet.info.name)?$store.state.cabinet.info.name:'Кабинет'}}</router-link>
                    <span v-if="$store.state.auth.token_status" class="btn-round btn-round--bg" @click='toggle = !toggle'><span class="cart-count">{{$store.state.cabinet.bells.orders.length}}</span></span>

                </div>
                <div class="top-navbar__btns" v-else>
                    <router-link :to="(countOfGoods>0)?'/cart':''" class="btn-round btn-round--grey me-2 me-md-0"><img :src="require('../assets/case-black.svg')" /><span class="cart-count cart-count--orange">{{countOfGoods}}</span></router-link>
                    <span v-if="$store.state.auth.token_status" class="btn-round btn-round--grey me-2 me-md-0 btn-dark-round d-md-none" @click="showMobMenu()"><img :src="require('../assets/cabMob.svg')" /></span>
                    <router-link v-if="$store.state.auth.token_status" to="/cabinet/contacts" class="btn-oval-low btn-oval-low--orange ms-4 me-2 d-none d-md-inline-block">{{($store.state.cabinet.info.name)?$store.state.cabinet.info.name:'Кабинет'}}</router-link>
                    <span v-if="$store.state.auth.token_status" class="btn-round btn-round--grey btn-round--bgs" @click='toggle = !toggle'><span class="cart-count cart-count--orange">{{$store.state.cabinet.bells.orders.length}}</span></span>
                </div>
                <div class="popoverBell" v-show='toggle && $store.state.cabinet.bells.orders.length' v-click-outside="onClickOutside">
                    <!-- <div class="popoverBell-item">
                        <div class="popoverBell-item__number">Заказ №5678</div>
                        <div class="popoverBell-item__status">собран и ожидает выдачи!</div>
                        <div class="popoverBell-item__warn">Необходимо забрать до 17:00</div>
                    </div> -->
                    <BellItem :item="item" v-for="item in $store.state.cabinet.bells.orders" :key="item.id" />
                    <!-- <BellItem /> -->
                    <!-- <BellItem /> -->
                </div>

            </div>
            <div class="col-6 col-md-5 d-flex justify-content-end" v-else>
                <button class="closeAdmin" @click='logout()'>Выход</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import BellItem from '@/components/BellItem.vue'
export default {
    data() {
        return {
            toggle: false
        }
    },
    components: {
        BellItem
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        countOfGoods() {
            return this.$store.getters.countCartItems
        }
    },
    async mounted() {
        if(this.$store.state.auth.token_status){
        await this.$store.dispatch("actionGetInfo");
        this.callBell(100);
       }
    },
    methods: {
        onClickOutside(event) {
            console.log(event.target._prevClass)
            if (event.target._prevClass != 'btn-round btn-round--bg' && event.target._prevClass != 'btn-round btn-round--grey btn-round--bgs') {
                this.toggle = false
            }
        },
        async callBell(counter) {
            if (this.$store.state.auth.token_status) {
                var th = this
                console.log(counter);
                counter++;
                await this.$store.dispatch("actionGetBell");
                window.setTimeout(function () {
                    th.callBell(counter);
                }, 60000)
            }
        },
        hideIfAdmin() {
            const myArray = this.$route.path.split("/");
            return !myArray.includes("admin");
        },
        async logout() {
            this.$store.dispatch("logout");
            this.$router.push('/')
             await this.$store.dispatch('actionGetCart');
        },
        showMobMenu(){
             this.$store.commit('mobMenu');
            
        }
    },

}
</script>

<style lang="scss" scoped>
.top-navbar {
    @extend .container;
    position: relative;
    z-index: 1000;
    padding-bottom: 30px;
}

.top-navbar__meta {
    font-size: 16px;
    line-height: 107.19%;

    a {
        color: $orange;
         @include media-breakpoint-only(md){
            display: inline-block;
            margin-bottom: 5px;
            margin-top: 5px;
         }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
     @include media-breakpoint-only(md){
        display: flex;
        flex-direction: column;
     }
}

.btn-round {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: #FF8D4B;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.top-navbar__logo {
    text-align: center;

    img {
        @include media-breakpoint-down(md) {
            max-width: 93px;
            height: auto;
        }
    }
}

.top-navbar__btns {
    display: flex;
    position: relative;
    z-index: 1000;
}

.btn-round--grey {
    background: #F1F1F1 !important;
}

.top-navbar-wrapper {
    background: #fff;
}

.top-navbar__burger {
    cursor: pointer;
    margin-right: 20px;
}

.cart-count {
    position: absolute;
    color: #fd7e14;
    background: #fff;
    width: 17px;
    top: -7px;
    right: -3px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 10px;

    &--orange {
        color: #fff;
        background: #fd7e14;
    }
}

/* Popover styling */

.popoverBell {
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    top: 94px;
    right: 0;
    background: #fff;
    color: #000;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 290px;
    padding-bottom: 0;
}

.popoverBell::after,
.popoverBell::before {
    content: '';
    position: absolute;
    background: #fff;
    right: 25px;
    top: -11px;
    width: 20px;
    height: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    z-index: -1;
    /* Прячем за основным блоком */
    transform: rotate(45deg);
    /* Поворачиваем на 45º */
    -webkit-transform: rotate(45deg);
}

.popoverBell::before {
    z-index: 1;
    /* Накладываем поверх, чтобы скрыть следы тени */
    box-shadow: none;
    /* Прячем тень */
}

.popoverBell-item {
    background: url(../assets/true.svg) 100% 10% no-repeat;
    font-size: 14px;
    font-family: 'Montserrat';
    padding-bottom: 19px;
}

.popoverBell-item__number {
    font-weight: 600;

}

.popoverBell-item__status {}

.popoverBell-item__warn {

    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;

    color: #FF3528;
}

.btn-round--bg {
    background: url(../assets/bell.svg) 50% 50% no-repeat #FF8D4B;

}

.btn-round--bgs {
    background: url(../assets/bell-black.svg) 50% 50% no-repeat #F1F1F1 !important;
}

.closeAdmin {
    width: 104px;
    height: 34px;
    background: #D4D4D4;
    border-radius: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: none;
    color: #000000;
}
.btn-dark-round{
      background:  #3F2A2C !important;
}
</style>
