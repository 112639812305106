import axios from "axios";
import store from "../store";
import router from "../router";

export default function axiosSetUp() { 
  axios.interceptors.request.use(
    function(config) {
      const token = store.state.auth.access_token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
   
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    async function(error) {
      const originalRequest = error.config;
      if(error.response.request.responseURL!=`https://elimleli2.itfactory.site/api/v1/personal/info` & error.response.request.responseURL!=`https://elimleli2.itfactory.site/api/v1/personal/balance/get`){
        if (
        error.response.status === 401
        // error.response.status === 401 &&
        // originalRequest.url.includes("api/Auth/updateToken")
      ) {
        store.commit('auth_success', {token_status:false});
        router.push("/");
        return Promise.reject(error);
      } else if (error.response.status === 401 && !originalRequest._retry) {
        //alert("refTok");
        originalRequest._retry = true;
        await store.dispatch("refreshToken");
        return axios(originalRequest);
      } else if (error.response.status === 403) {
        store.dispatch("logout");
        router.push("/");
      }
    }
      return Promise.reject(error);
    }
  );

  //store.dispatch("refreshToken")
}
