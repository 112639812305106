import axios from "axios";
import {BASE_URL} from './../consts';
export default {
    state: {
        params: {
            allProps:{
              136:{
                
              }
            },
            userFilter:{
                calories_left:null
            }
        },
        dietParam: [],
        kkal:{
          min: null,
          max:null
        },
        with_pre_filter:1
    },
    mutations: {
        SET_PRE_FILTER(state, payload) {
            state.with_pre_filter = payload;
        },
        decrement_kkal(state,kkal){
            const res = state.params.userFilter.calories_left -kkal
            state.params.userFilter.calories_left = Math.round(res)
        },
        diet_param(state, payload) {
            state.dietParam = payload;
        },
        kkal_param(state, payload) {
            state.kkal.min = payload.min;
            state.kkal.max = payload.max;
        },
        SET_FILTER_PARAMS(state, payload) {
            state.params = payload.params;
        },
        checkedParam(state, payload) {

            // state.goods = payload['asa'];
            let index = state.params.allProps['136']['availableOptions'].findIndex(el => el.value == payload);
            state.params.allProps['136']['availableOptions'][index].is_active = true;
            //console.log('ssss',state.goods.allProps['1']['options'][index])
            //alert(index)
        },
        removeParam(state, payload) {

            // state.goods = payload['asa'];
            let index = state.params.allProps['136']['availableOptions'].findIndex(el => el.value == payload);
            state.params.allProps['136']['availableOptions'][index].is_active = false;
            // console.log('ssss',state.goods.allProps['136']['options'][index])
            //alert(index)
        },
        filterParamByAvailable(state) {
            state.params.allProps['136']['availableOptions']?.forEach((elementA, index) => {
                if (state.params.allProps['136']['availableOptions'].indexOf(String(elementA.id)) == -1) {
                    state.params.allProps['136']['availableOptions'].splice(index, 1)
                }
            });
        },
    },

    actions: {
        async actionSetFilter({ commit }, data) {
            if(data.time.length!=5){
              data.time = `0${data.time}`
            }
            var res = await axios({
                url: `${BASE_URL}/group/${data.id}`,
                data: data,
                method: "POST",
            });
            console.log('dish', res.data)
            commit("SET_FILTER_PARAMS", {
                params: res.data,
            });
            return res;
        },
    },



    //   getters: {
    //     isLoggedIn: (state) => !!state.token,
    //     authStatus: (state) => state.status,
    //   },
};
