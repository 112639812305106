<template>
<div>
    <swiper :options="swiperOption" ref="swiper" class="swiper" @reachEnd="reachEnd" @reachBeginning="reachBeginning">
        <swiper-slide>
            <img :src="require('../assets/slide.png')" />
        </swiper-slide>
        <!-- <swiper-slide>
            <img :src="require('../assets/slide2.png')" />
        </swiper-slide> -->

          <div class="swiper-pagination" slot="pagination">d</div>
    </swiper>

</div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
//import FoodCategoryItem from '@/components/FoodCategoryItem.vue';

export default {
    name: "swiper-example-pagination-progress",
    title: "Progress pagination",
    components: {
        Swiper,
        SwiperSlide,
        //  FoodCategoryItem,
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: false,
                effect: 'fade',
                speed: 200,
                autoplay: false,
                // navigation: {
                //     nextEl: ".swiper-button-next",
                //     prevEl: ".swiper-button-prev",
                // },
                   pagination: {
            el: '.swiper-pagination'
          }
            },
        };
    },
    props: {
        mediaItems: {

        },

    },
    methods: {
        reachEnd() {
            // alert('end');
        },
        reachBeginning() {
            // alert('start');
        }
    },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
    // max-width: 250px !important;
    // width: 100%;
}

.swiper-container {
    position: relative;
    max-width: 599px;
    //height: auto;
    top: 150px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    //padding-top: 50%;
    //position: absolute;
    // left: 0;
    // right: 0;
    //padding-top: 50%;
    top: 196px;
    left: 16px;
    overflow: visible;
     @include media-breakpoint-only(md){
        max-width: 350px;
        top:150px;
       left: 30px;
     }
 @include media-breakpoint-only(xl){
    max-width: 450px;
 }
  @include media-breakpoint-only(lg){
    max-width: 450px;
 }
    @include media-breakpoint-down(md) {
       top: -75px;
        left: inherit;
        max-width: 270px;
    }

    &:before {

        width: 44px;
        height: 356px;
        top: 42%;

        right: 9%;

        @include media-breakpoint-down(md) {
            content: '';
            background: url(../assets/knife.png) 50% 50% no-repeat;
            position: absolute;
            width: 26px;
            height: 145px;
            background-size: contain;
            top: 25%;
            right: -10%;
            z-index: 9999;
        }
         @include media-breakpoint-only(xl){
              content: '';
            background: url(../assets/knife.png) 50% 50% no-repeat;
            position: absolute;
            width: 20px ;
            height: 10px ;
            background-size: contain;
            top: 25%;
            right: -10%;
            z-index: 9999;
         }
            @include media-breakpoint-only(lg){
              content: '';
            background: url(../assets/knife.png) 50% 50% no-repeat;
            position: absolute;
            width: 20px ;
            height: 10px ;
            background-size: contain;
            top: 25%;
            right: -10%;
            z-index: 9999;
         }
    }

    &:after {
      

        @include media-breakpoint-down(md) {
               content: '';
        position: absolute;
        background: url(../assets/fork.png) 50% 50% no-repeat;
            width: 26px;
            height: 145px;
            background-size: contain;
            top: 25%;
            left: -10%;
  z-index: 9999;
       
        }
    }
}

.swiper-slide {
    img {
        max-width: 100%;
        height: auto;
    }
}
.swiper-pagination{
    display: none;
}

</style>
