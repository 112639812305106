import {BASE_URL} from './../consts';
import axios from "axios";

export default {
  state: {
    token_status:localStorage.getItem("token_status") || false,
    access_token: localStorage.getItem("token") || "",
    refresh_token: localStorage.getItem("refresh_token") || "",
    expires_in: localStorage.getItem("expires_in") || "",
  },
  mutations: {
    smsResult(state) {
      state.smsResult = "";
    },
    auth_success(state, payload) {
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.token_status = payload.token_status;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state){
      state.token_status = false;
      state.access_token = '';
      state.refresh_token = '';
    }
  },

  actions: {
    async actionSendSms(_, phone) {
      const res = await axios({
        url: `${BASE_URL}/auth/sendsms?phone=7${phone}`,
        method: "POST",
      });
      console.log("Код из СМС (на время тестирования!):  " + res.data.code)
    },
    async actionRegistration({commit}, data) {
      var res = await axios({
        url: `${BASE_URL}/auth/auth`,
        data: data,
        method: "POST",
      });
      console.log('res.token',res.data)
      const token = res.data.token;
      const token_status = res.data.status;
      const refresh_token = res.data.refresh;
      localStorage.setItem("token", token);
      localStorage.setItem("token_status", token_status);
      commit("auth_success", {
        access_token: token,
        token_status: token_status,
        refresh_token: refresh_token
      });
      return res;
    },


    async actionTestRefresh() {
      var res = await axios({
        url: `${BASE_URL}/auth/test`
      });
    console.log('result',res)
    },


    async refreshToken({ commit }) {
      try {
        var res = await axios({
          url: `${BASE_URL}/auth/refresh`,
          data: {
            token: localStorage.getItem("token"),
          },
          method: "POST",
        });
        if (res.status == 200) {

          const token = res.data.token;
          const token_status = res.data.status;
          const refresh_token = res.data.refresh;
          localStorage.setItem("token", token);
          localStorage.setItem("token_status", token_status);
          commit("auth_success", {
            access_token: token,
            token_status: token_status,
            refresh_token: refresh_token
          });
          console.log("refTok", res);
          return await res;
        } else {
          //alert(resp.status);
        }
      } catch (error) {
       // alert(error.response);
      }
      //   //commit('get_user_info', {"user":res.data.UserName, "fio":res.data.UserFIO})
    },
    // async actionGetToken({ commit }) {
    //   const res = await axios({
    //     url: "https://elimleli2.itfactory.site/api/v1/auth/login",
    //     data: { phone: "998909798110", password: 9780 },
    //     method: "POST",
    //   });
    //   const token = res.data.access_token;
    //   const token_type = res.token_type;
    //   const expires_in = res.data.expires_in;
    //   localStorage.setItem("token", token);
    //   localStorage.setItem("token_type", token_type);
    //   localStorage.setItem("expires_in", expires_in);
    //   //axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //   commit("auth_success", {
    //     access_token: token,
    //     token_type: token_type,
    //     expires_in: expires_in,
    //   });
    // },

    logout({ commit }) {
      localStorage.removeItem("token");
      localStorage.removeItem("token_status");
      //localStorage.removeItem("role");
      //localStorage.removeItem("refresh_id");
      commit("logout");
      delete axios.defaults.headers.common["Authorization"];
    },
  },
  getters: {
    isLoggedIn: (state) => state.token_status,
    authStatus: (state) => state.token_status,
  },
};
