<template>
<div class="category-list">
    <div class="swiper-header">
        <h4 class="header-block md-3 mb-md-4 pt-4 pt-md-3  mt-4">Категория блюда:</h4>
        <div class="swiper-button">
            <div @click="$refs.swiper.swiperInstance.slidePrev()" class="button-prev"></div>
            <div @click="$refs.swiper.swiperInstance.slideNext()" class="button-next"></div>
        </div>
    </div>
    <swiper :options="swiperOption" ref="swiper" class="swiper" @reachEnd="reachEnd" @reachBeginning="reachBeginning">
        <!-- Loops through item data and creates a carousel item -->
        <swiper-slide v-for="cat in category" :key="cat.id">
             <FoodCategoryItem :cat='cat' catImg="desserts.svg"/>
        </swiper-slide>
    
    </swiper>

</div>
</template>

<script>
import {
    Swiper,
    SwiperSlide
} from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
import FoodCategoryItem from '@/components/FoodCategoryItem.vue';
import { mapState } from 'vuex'
export default {
    name: "swiper-example-pagination-progress",
    title: "Progress pagination",
    components: {
        Swiper,
        SwiperSlide,
        FoodCategoryItem,
    },
    data() {
        return {
            swiperOption: {
               
           spaceBetween: 10,
               
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                       breakpoints: {
                    // when window width is >= 320px
                    1000: {
                        slidesPerView: 4,
                         loop: false,
                     ///   spaceBetween: 10,
                    
                    },
                    // when window width is >= 780px
                    800: {
                        slidesPerView: 8,
                         loop: true,
                     //   spaceBetween: 10,
                      
                    }
                }
            },
            d: new Date()
        };
    },
    props: {
        mediaItems: {

        },
triger: Number
    },
         computed: {
        ...mapState([
            'category'
        ])

    },
    watch:{
      triger(){
         this.$refs.swiper.swiperInstance.update();
      }
    },
         async mounted(){

    },
    methods: {
        reachEnd() {
           // alert('end');
        },
        reachBeginning() {
           // alert('start');
        }
    },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
    max-width: 250px !important;
    width: 250px !important;
}

.swiper-container {
    max-width: 1295px;
    width: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
}

.swiper-button {
    position: absolute;
    top: 0;
    right: 5px;
    display: flex;
    width: 90px;
    justify-content: space-between;
}

.button-prev,
.button-next {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    border: 1px solid $orange;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        position: absolute;
        content: '';
        border: solid $orange;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
    }
}

.button-next {

    &:before {
        position: absolute;
        content: '';
        border: solid $orange;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
    }
}

.button-next {

    &:before {
        position: absolute;
        content: '';
        border: solid $orange;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
    }
}

.swiper-header {
    position: relative;
}
.swiper-slide{
    width: 250px;
}
.category-list{
    width: 100%;
}
</style>
