<template>
<footer class="footer">
    <div class="container">
        <div class="row align-items-center align-items-md-start">
            <div class="col-6 col-md-3">
                <router-link to="/" class="footer__logo"><img :src="require('../assets/logo.png')" /></router-link>
            </div>
            <div class="col-md-3 d-none d-md-block">
                <div class="footer__contact">
                    <b>Связаться с нами:</b>
                    <div class="phoneCity">
                    <a href="tel:+79281296165" class="mt-2 mb-2">+7 (928) 129-61-65</a>&nbsp;<i>Батайск</i>
                    </div>
                    <div class="phoneCity">
                    <a href="tel:+79281303277" class="mt-2 mb-2">+7 (928) 130-32-77</a>&nbsp;<i>Ростов-на-Дону</i>
                    </div>
                    <span>С 9 до 18 (Пн-Пт)</span>
                    <a href="mailto:zakaz@eli-mleli.ru" class="mt-3">zakaz@eli-mleli.ru</a>
                </div>
            </div>
            <div class="col-6 col-md-2 d-none d-lg-block">
                <div class="footer__socials ms-3 d-none">
                    <a href="#"><img :src="require('../assets/vk.svg')" /></a>
                    <a href="#"><img :src="require('../assets/fb.svg')" /></a>
                    <a href="#"><img :src="require('../assets/inst.svg')" /></a>
                </div>
            </div>
            <div class="col-md-4 d-none d-md-block">
                <div class="footer__policy ps-5">
                    <a href="#">Политика конфиденциальности и оферта </a>
                    <a href="#">Пользовательское соглашение </a>
                </div>
            </div>
        </div>
    </div>
    <div class="footerContactMob d-md-none">
        <div class="footer__contact">
            <b>Связаться с нами:</b>
           <div class="phoneCity">
                    <a href="tel:+89281296165" class="mt-2 mb-2">+8 (928) 129-61-65</a>&nbsp;<i>Батайск</i>
                    </div>
                    <div class="phoneCity">
                    <a href="tel:+89281303277" class="mt-2 mb-2">+8 (928) 130-32-77</a>&nbsp;<i>Ростов-на-Дону</i>
                    </div>
            <span>С 9 до 18 (Пн-Пт)</span>
            <a href="mailto:zakaz@eli-mleli.ru" class="mt-3">zakaz@eli-mleli.ru</a>
        </div>
    </div>
    <ModalLogin />
    <ModalDate />
    <MobileMenu />
    <!-- <ModalAddEdit /> -->
</footer>
</template>

<script>
import ModalLogin from '@/components/ModalLogin.vue'
import ModalDate from '@/components/ModalDate.vue'
// import ModalAddEdit from '@/components/ModalAddEdit.vue'
import MobileMenu from '@/components/MobileMenu.vue'
export default {
    components: {
        ModalLogin,
        ModalDate,
        MobileMenu,
        // ModalAddEdit
    },
}
</script>

<style lang="scss" scoped>
.footer {
    background: #F5F5F5;
    padding-top: 80px;
    padding-bottom: 40px;

    @include media-breakpoint-down(md) {
        padding-top: 33px;
        padding-bottom: 0;
    }

    &__logo {
        img {
            max-width: 100%;
            height: auto;

            @include media-breakpoint-down(md) {
                max-width: 108px;
            }
        }
    }

    &__contact {
        a {
            display: block;
            font-weight: bold;
            font-size: 16px;
            line-height: 107.19%;
            color: $orange;
        }
    }

    &__socials {
        @include media-breakpoint-down(md) {
            text-align: right;
        }

        a {
            display: inline-block;
            margin-right: 12px;
        }
    }

    &__policy {
        a {
            font-family: Montserrat;
            display: inline-block;
            margin-bottom: 18px;
            font-size: 16px;
            line-height: 107.19%;
            text-decoration: none;

            color: #323232;
        }
    }
}

.footerContactMob {
    background: #fff;
    width: 100%;
    text-align: center;
    padding-bottom: 25px;
    margin-top: 20px;
    padding-top: 30px;
}
.phoneCity{
    display: flex;
    align-items: center;
    i{
        font-style: inherit;
    }
     @include media-breakpoint-down(md){
        justify-content: center;
     }
}
</style>
