import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
//import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import SwiperCore, {Pagination, EffectFade} from 'swiper'
import Popover from 'vue-js-popover'
import axiosSetup from "./helpers/axios"
import Loader from './components/Loader'
import Vuelidate from 'vuelidate'
import moment from 'moment';
import 'moment/dist/locale/ru';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
//import VueHtmlToPaper from 'vue-html-to-paper';
import VueHead from 'vue-head'

moment.locale('ru');

Vue.prototype.$moment = moment;
Vue.config.productionTip = false

//Vue.use(VueDatePicker);
SwiperCore.use([Pagination, EffectFade]);
// Options: You can set lang by default


axiosSetup()
const options = {
  position: "bottom-right",
  hideProgressBar: true,
  timeout: 2000
};
Vue.use(Toast, options); 
Vue.use(Vuelidate)
Vue.use(Popover)
Vue.use(VueDatePicker, {
  lang: 'ru'
});
Vue.use(VueHead)
//Vue.use(VueHtmlToPaper);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.component("Loader",Loader)