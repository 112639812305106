<template>
        <div class="mobileMenu" v-if="$store.state.showMobMenu">
        <div class="navbar-vertical-mob" v-click-outside="onClickOutsides">
            <span @click="hideMenu('/cabinet/contacts')" class="navbar-vertical-mob__btn">Контактная информация</span>
            <span @click="hideMenu('/cabinet/history')" class="navbar-vertical-mob__btn">История заказов</span>
            <span @click="hideMenu('/cabinet/bill')" class="navbar-vertical-mob__btn">Внутренний счет</span>
            <span @click="hideMenu('/cabinet/filter')" class="navbar-vertical-mob__btn">Настройка фильтров</span>
            <span @click="hideMenu('/cabinet/auto')" class="navbar-vertical-mob__btn">Автозаказ</span>
            <span @click="hideMenu('/#menu')" class="navbar-vertical-mob__btn">Вернуться в меню</span>
            <button @click='logout()' class="navbar-vertical-mob__btn navbar-vertical-mob__btn--white mt-3">Выйти из аккаунта</button>
        </div>
    </div>
</template>
<script>
import vClickOutside from 'v-click-outside'
export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
        methods: {
        hideMenu(path) {
            this.$router.push(path)
            this.$store.commit('mobMenu');
        },
          async logout() {
            this.$store.dispatch("logout");
            this.$router.push('/')
            await this.$store.dispatch('actionGetCart');
        },
            onClickOutsides(event) {
            console.log(event.target._prevClass)
            if (event.target._prevClass != 'btn-round btn-round--bg' && event.target._prevClass != 'btn-round btn-round--grey btn-round--bgs') {
               this.$store.commit('mobMenu');
            }
        }
    },
}
</script>
<style lang="scss" scoped>
    .mobileMenu {
    position: absolute;
    top: 95px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: #F5F5F5;
    z-index: 9;
    padding-top: 30px;

}

////////////////////////////////////////////////////////////////////
.navbar-vertical-mob {
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;

    &__btn {
        @extend .btn;
        width: 100%;
        padding-top: 22px;
        padding-bottom: 23px;
        margin-bottom: 10px;
        background: #FF7728;
        border-radius: 12px;
        font-family: Montserrat;

        font-weight: 500;
        font-size: 16px;
        line-height: 109.5%;
        /* identical to box height, or 18px */

        color: #fff;

        &--white {
            background: #fff !important;
            color: #636363;
            &:hover{
                  color: #636363 !important;
            }
        }

        &:hover {
            background: $orange;
            color: #fff;
        }
    }
}

.router-link-exact-active {
    background: $orange;
    color: #fff;
}
</style>