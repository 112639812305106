<template>
<div class="col-6 col-md-3 mb-4">
    <div class="food-item">
        <div class="food-item__img" v-if="good.files.length!=0">
            <img :src="`https://elimleli2.itfactory.site${good.files[0].thumb_path}`" /></div>
            <div class="food-item__noimg" v-else></div>
        <!-- <div class="food-item__img" v-else><img :src="require('../assets/dish.jpg')" /></div>  -->
        <div class="food-item__meta">
            <div class="food-item__name">{{good.name}}</div>
            <div class="food-item__kkal" :class="colorText(good.propertyValues['138'].values[0])">{{good.propertyValues['138'].values[0]}} ккал</div>
            <div class="food-item__weight">{{good.propertyValues['137'].values[0]}} гр <span class="disc"></span> БЖУ: {{good.propertyValues['141'].values[0]}} / {{good.propertyValues['142'].values[0]}} / {{good.propertyValues['143'].values[0]}}</div>
            <div class="food-item__desc">{{good.desc}}</div>
       <!-- TODO   Change ID -->
        </div>
        <div class="food-item__bottom">
            <div class="row w-100">
                <div class="col-md-4 d-none d-md-block">
                    <div class="food-item__price">{{good.price}}<span>рублей</span></div>
                </div>
                <div class="col-md-8 d-flex justify-content-center justify-content-md-end"><button class="food-item__add-cart btn-orange-middle" :disabled='itemCurrent==good.id || disableButtonAdd(good.id)' v-on:click="addToCart(good.id, good.propertyValues['138'].values[0])"><span class="d-none d-md-inline">В корзину</span><span class="d-md-none">{{good.price}} рублей</span></button></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            itemCurrent:null,
        }
    },
    props: {
        good: {
            type: Object,
        }
    },
    methods: {
        async addToCart(item, kkal) {
            if (this.$route.name != 'CartEdit') {
              this.itemCurrent = item
               const res = await this.$store.dispatch('actionAddToCart', {
                    id: item,
                    date: this.$moment(this.$store.state.cart.date).format('YYYY-MM-DD'),
                    hour: this.$store.state.cart.hour
                });
                if(res.status){
                 this.$toast.success("Продукт добавлен в корзину");
                } else {
                  this.$toast.info(res.message.date_time[0]);
                }
                
                await this.$store.dispatch('actionGetCart');
                const data = {
                    id: this.$store.state.cart.catId,
                    date: this.$moment(this.$store.state.cart.date).format("YYYY-MM-DD"),
                    time: this.$store.state.cart.hour,
                    with_pre_filter: this.$store.state.filter.with_pre_filter
                };
                //Уменьшаем значение движка ккал, если не авторизован или тронуты фильтры
                if (!this.$store.state.auth.token_status || this.$store.state.filter.with_pre_filter == 0) {
                    this.$store.commit('decrement_kkal', kkal);
                }
                if (this.$store.state.filter.with_pre_filter == 1) {
                    await this.$store.dispatch("actionSetFilter", data);
                }
            } else {
           this.itemCurrent = item
                    const res = await this.$store.dispatch('actionAddToCartEdit', {
                        id: item,
                        cartId: this.$store.state.cart.cartEditId
                    });
                     
                    console.log('target',res)
                    if(res.status){
                       this.$toast.success(res.message)
                       await this.$store.dispatch("actionGetBalance");
                    } else {
                       this.$toast.info(res.message)
                    }
                    
               

                const data = {
                    id: this.$store.state.cart.catId,
                    date: this.$moment(this.$store.state.cart.date).format("YYYY-MM-DD"),
                    time: this.$store.state.cart.hour,
                    with_pre_filter: this.$store.state.filter.with_pre_filter,
                    cart_item_id:this.$store.state.cart.cartEdit.orders.cart_info.times[0].cart_item_id
                };
                if (this.$store.state.filter.with_pre_filter == 1) {
                    await this.$store.dispatch("actionSetFilter", data);
                } else {
                    this.$store.commit('decrement_kkal', kkal);
                }
                await this.$store.dispatch("actionGetCartEdit", this.$route.params.id);
                //   await this.$store.dispatch('actionGetCartEdit');
            }
        },
        colorText(kkal) {

            if (+kkal < this.$store.state.filter.params.userFilter.calories_left) {
                return 'green'
            } else if (+kkal > this.$store.state.filter.params.userFilter.calories_left && +kkal <= this.$store.state.filter.params.userFilter.calories_left * 1.1) {
                return 'yellow'
            } else if (+kkal > this.$store.state.filter.params.userFilter.calories_left * 1.1) {
                return 'red'
            }

            //this.$store.state.filter.params.userFilter.calories_left

        },
        disableButtonAdd(id){
            console.log('id',id)
            var flag = false
            if(this.$store.state.cart.cart.cart_items){
            this.$store.state.cart.cart.cart_items.forEach(element => {
                 element.products.forEach(element => {
                     if(element.id == id){
                       flag=true
                     } 
                 });
             });
             }
             console.log('flag',flag)
             return flag;
        }
    },
    mounted() {
        console.log(this.$store.state.cart.TotalPositions)
    },

}
</script>

<style lang="scss" scoped>
.food-item {
    position: relative;
    background: #F5F5F5;
    border-radius: 12px;
    // margin-bottom: 37px;
    height: 100%;

    @include media-breakpoint-down(md) {
        margin-bottom: 10px;
    }
}

.food-item__img {
    max-height: 255px;
    overflow: hidden;
    border-radius: 12px 12px 0 0;

    img {
        width: 100%;
        height: auto;
    }
}

.food-item__noimg{
    padding-top: 82%;
    width: 100%;
        border-radius: 12px 12px 0 0;
      background: url(../assets/nophoto.png) 50% 50% no-repeat #EBEBEB;
      @include media-breakpoint-only(xs){
        padding-top: 100%; 
      }
    @include media-breakpoint-only(sm){
        padding-top: 86%; 
      }
      @include media-breakpoint-only(md){
        display: none;
      }
}

.food-item__meta {
    padding: 15px 20px;
position: relative;
//height: 200px;
height: auto;
    padding-bottom: 74px;
    @include media-breakpoint-down(md) {
        padding: 12px 10px;
        padding-bottom: 70px;
    }
}

.food-item__name {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 3px;

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 23px;
    }

    span {}
}

.food-item__kkal {
    font-family: Montserrat;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    color: #00BC13;
}

.food-item__weight {
    font-size: 14px;
    line-height: 17px;
    font-family: Montserrat;
    margin-bottom: 10px;

    align-items: center;

    color: #828282;
}

.food-item__desc {
    font-family: Montserrat;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 17px;

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.food-item__price {
    color: $orange;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    span {
        display: block;
        font-size: 14px;
        line-height: 19px;
        font-weight: normal;
    }
}

.food-item__add-cart {}

.btn-orange-middle {
    @extend .btn;
    background: $orange;
    white-space: nowrap;
    border-radius: 12px;
    font-weight: 600;
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    padding: 14px 22px;

    &:hover {
        background: none;
        border: 1px solid $orange;
        transition: all .2s ease;
        color: $orange;
    }
}

.yellow {
    color: #FF7728;
}

.red {
    color: red;
}
.food-item__bottom{
    position: absolute;
    bottom: 20px;
    display: flex;
    left: 20px;
    right: 0;
}
.disc{
    background: #828282;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    position: relative;
    top: -2px;
    display: inline-block;
}
</style>
