<template>
<div>
    <!-- Modal -->
    <div class="modal fade" id="login" tabindex="-1" aria-labelledby="login" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <img :src="require('../assets/closeModal.svg')" />
                </button>
                <form @submit.prevent autocomplete="on">
                    <div class="modal-body login">
                        <h1 class="login__header">Вход на сайт</h1>
                        <p>
                            Новости и акции, а так же удобная система управления заказами
                        </p>
                        <h2 class="messageText" v-if="showText.length > 0">
                            {{ showText }}
                        </h2>
                        <div class="addMenuItemInput" v-if="showPhone">
                            <label for="tel">Номер телефона</label>
                             <the-mask mask="7 (EEE) EEE EE EE" :tokens="hexTokens" placeholder="7 (XXX) XXX XX XX" v-model="phone" />
              
                            <!-- <input type="text" v-model="phone"> -->
                            <div class="invalid-feedback" v-if="smsResult">
                                {{errorMessage}}
                            </div>
                        </div>
                        <div class="addMenuItemInput" v-if="showSms">
                            <label for="tel">Введите код из SMS</label>
                            <input v-model="code" />
                            <div class="valid-feedback" v-if="countDown > 0">
                                Отправить снова: {{ countDown }} с.
                            </div>
                            <div class="valid-feedback sendAgain" v-on:click="sendSms" v-if="countDown == 0">
                                Отправить снова
                            </div>
                            <div class="invalid-feedback" v-if="smsNumberResult">
                                {{errorMessage}}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer mt-4 mb-5 pb-2">
                        <button class="btn-oval" v-on:click="sendSms" v-if="showPhone">
                            Получить код
                        </button>
                        <button class="btn-oval" v-on:click="sendCode" v-if="showSms">
                            Войти
                        </button>
                        <div class="modal-footer__desc">
                            Нажимая кнопку “Войти”, вы соглашаетесь
                            <span> со сбором и обработкой персональных данных и
                                пользовательским соглашением</span>
                        </div>
                        <!-- //////////////////////////////////////////////////////// -->
                        <!-- <label for="tel">Войти</label>
              тел
                   <input v-model="phone" />
                   смс
                <input v-model="code" /> -->
                        <!-- <button class="btn-oval" v-on:click="getToken">
               Проверить токен
              </button> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import axios from "axios";
export default {
    components: {
    TheMask,
    },
    data() {
        return {
            hexTokens: {
                F: {
                    pattern: /[7]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
                E: {
                    pattern: /[0-9]/,
                    transform: (v) => v.toLocaleUpperCase(),
                },
            },
            disabled: false,
            phone: "",
            code: "",
            smsResult: false,
            smsNumberResult: false,
            showPhone: true,
            showSms: false,
            showText: "",
            countDown: null,
            errorMessage: ''
        };
    },
    computed: {
        isDisabled() {
            console.log(this.phone.length);
            return this.phone.length == 11 ? false : true;
        },
    },
    methods: {
        async sendSms() {
            try {
                await this.$store.dispatch("actionSendSms", this.phone);
                this.showPhone = false;
                this.showSms = true;
                this.countDown = 10
                this.countDownTimer();
            } catch (error) {
                this.smsResult = true;
                this.errorMessage = error.response.data.message
                // alert(error.response.data.message);
            }
        },
        async sendCode() {
            const data = {
                code: this.code,
                phone: `7${this.phone}`,
            };
            try {
                const res = await this.$store.dispatch("actionRegistration", data);
                // const datas = {
                //     id: this.$store.state.cart.catId,
                //     date: this.$moment(this.$store.state.cart.date).format('YYYY-MM-DD'),
                //     time: this.$store.state.cart.hour,
                //     with_pre_filter: 1
                // }
                // this.$store.commit('loader', true);
                // await this.$store.dispatch("actionGetDish", datas);
                // this.$store.commit('loader', false);
                // await this.$store.dispatch("actionSetFilter", datas);
                this.showPhone = false;
                this.showSms = false;
                this.showText = res.data.message;
       
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
             
            } catch (error) {
                this.smsNumberResult = true;
                this.errorMessage = error.response.data.message
                // alert(error.response.data.message);
                //console.log(error.response.data.message)
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            }
        },
        async getToken() {
            try {
                var res = await axios({
                    url: ""
                });
                //  alert(res.data.status)
                console.log('result', res)
            } catch (error) {
                alert(error.response.data.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.addMenuItemInput {
    max-width: 306px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    label {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
    }

    input {
        background: #ebebeb;
        border-radius: 73px;
        border: none;
        padding: 15px 10px 15px 15px;
        width: 100%;
        font-family: Montserrat;
        font-size: 16px;
        text-align: center;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &--bold {
        label {
            font-weight: 700;
            padding-left: 0;
        }
    }
}

.modal-body {
    padding: 63px 31px 0 32px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 645px;
    }
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.login {
    text-align: center;

    &__header {
        font-size: 40px;
        line-height: 44px;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 0;
        }
    }

    p {
        font-family: Montserrat;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        line-height: 142.69%;
        margin-top: 14px;
        color: #343434;
    }
}

.modal-footer__desc {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;
    font-size: 14px;

    span {
        color: $orange;
    }
}

.invalid-feedback,
.valid-feedback {
    display: block;
}

.valid-feedback {
    color: #343434;
}

.sendAgain {
    cursor: pointer;
}
</style>
