<template>
<div>
    <!-- Modal --> 
    <div class="modal fade" ref="bs" id="modalDate" tabindex="-1" aria-labelledby="modalDate" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" id='btn-close' data-bs-dismiss="modal" aria-label="Close">
                    <img :src="require('../assets/closeModal.svg')" />
                </button>
                <div class="modal-body login">
                    <h1 class="login__header">Выберите день и время</h1>
                    <div class="addMenuItemInput">
                        <label for="tel">День недели:</label>
                        <div class="calendar">
                            <VueDatePicker v-model="date" :min-date="currentDate" :allowed-dates="allowedDates" no-header no-calendar-icon format="dd, DD.MM" />
                        </div>
                    </div>
                    <div class="addMenuItemInput mt-3">
                        <label for="tel">Время:</label>
                        <select name="select" @change="onChangeHour($event)"> 
                            <!--Supplement an id here instead of using 'name'-->
                            <option v-for="(hour,index) in hoursList" :key="index" :value="hour" :selected='selectHour(hour)'>{{hour}}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer mt-4 mb-5 pb-2">
                    <button class="btn-oval" @click="selectDate">Применить</button>
                    <div class="modal-footer__desc">
                        Авторизовавшись, вы сможете создать автозаказ блюд, оставлять
                        отзывы и оплачивать с внутреннего счета
                    </div>
                </div>
                <!-- <button @click="addToCart(14)">ADD</button> -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            currentDate: new Date(),
            date: sessionStorage.getItem("date"),
            hoursList: [],
            week: [],
            todayDate: new Date(this.$moment(new Date()).format('YYYY-MM-DD')),
            todayDateFormat: this.$moment(new Date()).format('YYYY-MM-DD'),
            startHour: 8,
            startMinutes: 0,
            currentHour: null,
            endTime: '',
            endHour:'',
            endMinuts:''
        };
    },
    async mounted() {
        
        await this.$store.dispatch("actionGetTime");
        const endTime = this.$store.state.time_list.periods[2].time_end
        this.endTime = endTime
        this.endHour = Number(`${this.endTime[0]}${this.endTime[1]}`)
        this.endMinuts =  Number(`${this.endTime[3]}${this.endTime[4]}`)
        const hourMinut = new Date()
        const currentHour = hourMinut.getHours()
        this.currentHour = currentHour
        //Формируем список рабочих дней из тех дат которые >= сегодня
        let week = []
        await this.$store.dispatch("actionGetDayOfWeek");
        for (let index = 0; index < 7; index++) {
            var res = this.$store.state.admin.days.find(item => item.date == this.$moment(new Date(new Date().setDate(new Date().getDate() + index))).format("YYYY-MM-DD"))
            if (res == undefined) {
                let dateItem = new Date(this.$moment(new Date(new Date().setDate(new Date().getDate() + index))).format("YYYY-MM-DD"))
                if (+dateItem >= +this.todayDate) {
                    week.push(this.$moment(dateItem).format("YYYY-MM-DD"))
                }
            }
        }
        week.splice(0, 1);
        this.week = week
        //Если время с 19:00-23:00 то переключаем календрарь на след день и вываливаем весь список времени
        if (currentHour >= 0 & currentHour <= 19) {
            //Задаем старт для генерации часов
            this.startHour = 8;
            this.startMinutes = 0;
            //Фиксим выполнение при каждом входе, что бы не уходила дата
            sessionStorage.removeItem('day')
            //sessionStorage.removeItem('night')
            var sessionStorageDate = new Date(sessionStorage.getItem("date"));
            if (!sessionStorage.getItem("evening") || (+sessionStorageDate <= +this.todayDate)) {
                this.$store.commit('SET_HOUR', `8:00`);
                sessionStorage.setItem("time", `8:00`);
                sessionStorage.setItem("date", this.week[0]);
                this.$store.commit('SET_DATE', this.week[0]);
                this.date = this.week[0];
                // this.week.splice(0, 1);
                sessionStorage.setItem("evening", true);

            }
        } else {
            //this.week.splice(0, 1);
            this.startHour = 8;
            this.startMinutes = 0;
            //Фиксим выполнение при каждом входе, что бы не уходила дата
           // sessionStorage.removeItem('day')
            sessionStorage.removeItem('evening')
            var sessionStorageDateSec = new Date(sessionStorage.getItem("date"));
            if (!sessionStorage.getItem("day") || (+sessionStorageDateSec <= +this.todayDate)) {
                this.$store.commit('SET_HOUR', `8:00`);
                sessionStorage.setItem("time", `8:00`);
                sessionStorage.setItem("date", this.week[1]);
                this.$store.commit('SET_DATE', this.week[1]);
                this.date = this.week[1];
              
                sessionStorage.setItem("day", true);
            }
             this.week.splice(0, 1);
        }
        // if (sessionStorage.date) {
        //     // Если есть данные и выбиарем старт часов по времени от даты = |=
        //     if (this.week[0] == sessionStorage.date) {
        //         this.startMinutes =0;
        //         this.generateHoursList(8, 0);
        //     } else {
        //         this.startHour = 8
        //         this.startMinutes = 0
        //         this.generateHoursList(this.startHour, this.startMinutes);
        //     }

        // }
        this.generateHoursList(this.startHour, this.startMinutes);
    },
    watch: {
        date() {
            this.hoursList.length = 0
            sessionStorage.setItem("date", this.date);
            this.$store.commit('SET_DATE', this.date);
             this.generateHoursList(8, 0);
          // if (this.week[1] != this.date) {
            //     this.generateHoursList(8, 0);
            // } else {
            //   this.generateHoursList(8, 0);
            // }
        }
    },
    methods: {
        onChangeHour: function (event) {
            this.$store.commit('SET_HOUR', event.target.value);
            sessionStorage.setItem("time", event.target.value);
        },
        async selectDate() {
            document.getElementById('btn-close').click();
            const data = {
                id: this.$store.state.cart.catId,
                date: this.$moment(this.$store.state.cart.date).format('YYYY-MM-DD'),
                time: this.$store.state.cart.hour,
                with_pre_filter: this.$store.state.filter.with_pre_filter
            }
            this.$store.commit('loader', true);
            await this.$store.dispatch("actionGetDish", data);
            await this.$store.dispatch("actionSetFilter", data);
            this.$store.commit('loader', false);

        },
        allowedDates: function (date) {
            return this.week.some(item => JSON.stringify(item) == JSON.stringify(date));
        },

        selectHour(hour) {
            return (hour == this.$store.state.cart.hour) ? true : false
        },
        generateHoursList(h, m) {
            this.hoursList.length = 0
            for (var i = h; i < this.endHour; ++i) {
                for (var j = i == h ? Math.ceil(m / 30) : 0; j < 2; ++j) {
                    this.hoursList.push(i + ':' + (j * 30 || '00'));
                }
            }
            //if(this.hoursList.length==0){
           // this.hoursList.push('17:00')
            // }
        },
        splitHour(time) {
            return time.split(':')[0] * 60 + time.split(':')[1] * 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.addMenuItemInput {
    max-width: 306px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;

    @include media-breakpoint-down(md) {
        margin-top: 15px;
    }

    label {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
    }

    input {
        background: #ebebeb;
        border-radius: 73px;
        border: none;
        padding: 15px 10px 15px 25px;
        width: 100%;
        font-family: Montserrat;
        font-size: 16px;

        &:focus {
            border: none;
            outline: none;
        }
    }

    &--bold {
        label {
            font-weight: 700;
            padding-left: 0;
        }
    }
}

.modal-body {
    padding: 63px 31px 0 32px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 645px;
    }
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.btn-close {
    position: absolute;
    background: none;
    right: -64px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.login {
    text-align: center;

    &__header {
        font-size: 40px;
        line-height: 44px;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 0;
        }
    }

    p {
        font-family: Montserrat;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        line-height: 142.69%;
        margin-top: 14px;
        color: #343434;
    }
}

.modal-footer__desc {
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 15px;

    span {
        color: $orange;
    }
}

.modal-content {
    background: #f5f5f5;
}

.vd-picker__input {
    position: relative;

    input {}

    &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;

        right: 23px;
    }
}

.calendar {
    position: relative;
    background: url(../assets/calendar-orange.svg) 85% 50% no-repeat !important;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

select {
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 9px;
    height: 48px;
    width: 103px;
    background: none;
    padding-left: 22px;
    padding-right: 10px;

    &:focus {
        outline: none;
    }
}

select {
    appearance: none;
    background: url(../assets/selectArr.png) 85% 50% no-repeat !important;
}
</style>
