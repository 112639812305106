<template>
<div class="select-time pt-3">
    Дата и время: <b>{{$moment($store.state.cart.date).format('ddd, DD.MM')}}, {{$store.state.cart.hour}}</b>
    <span  ref="myBtn" class="select-time__change-time mt-2 pt-1" data-bs-toggle="modal" data-bs-target="#modalDate" v-if="$route.path == '/'">Изменить</span>
</div>
</template>
<script>
export default {
    mounted() {
        this.myClickEvent()
    },
     methods: {
        myClickEvent() {
            if(!sessionStorage.first){
            const elem = this.$refs.myBtn
            elem.click()
            sessionStorage.setItem("first", true);
            }
        }
    }
}
</script>
<style lang="scss">
    .select-time {
    font-size: 24px;
    line-height: 109.5%;
    position: relative;
font-weight: 400;
    b {
        @include media-breakpoint-down(md) {
            display: block;
            margin-top: 7px;
        }
    }

    span {
        display: block;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 14px;
        line-height: 109.5%;
            width: fit-content;
        color: $orange;
       cursor: pointer;
        @include media-breakpoint-down(md) {
            display: block;
            position: absolute;
            top: 35px;
            right: 20px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 109.5%;
    }
}
</style>