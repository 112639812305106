import {BASE_URL} from './../consts';
import axios from "axios";

export default {
    state: {
        info: {},
        groupList:[],
        color:{
            color: null,
            group_id: null
        },
        
        ordersList:[],
        order_info:{
            id:null,
            user:{
                user:{
                    name:''
                }
            }
        }

    },
    mutations: {
        SET_GROUP_LIST(state, payload) {
            state.groupList = payload.groupList;
        },

        SET_GROUP_COLOR(state, payload) {
            state.color.color = payload.color;
            state.color.group_id = payload.id;
        },
        SET_OPERATOR_ORDERS(state, payload) {
            state.ordersList = payload.ordersList;
        },
        SET_SMS_INFO(state, payload) {
            state.order_info = payload;
        },

    },

    actions: {
        async actionSetColorGroup({commit}, data) {
            try {
                await axios({
                    url: `${BASE_URL}/operator/group/setcolor?group_id=${data.group_id}&color=${data.color}`,
                    data: data,
                    method: "POST",
                });
                const res = await axios({
                    url: `${BASE_URL}/groups`,
                    method: "POST",
                });
                commit("SET_GROUP_LIST", {
                    groupList: res.data,
                });

            } catch (error) {
                alert(error)
            }


        },
        async actionGetAllGroups({ commit }) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/groups`,
                    method: "POST",
                });
                commit("SET_GROUP_LIST", {
                    groupList: res.data,
                });
            } catch (error) {
                alert(error)
            }

        },
        async actionGetListStatus({ commit }) {
            try {
                await axios({
                    url: `${BASE_URL}/operator/status/getlist`,
                });
                commit("smsResult", {
           
                });
            } catch (error) {
                alert(error)
            }

        },
        async actionGetListOrder({ commit },data) {
            try {
                const res = await axios({
                   url: `${BASE_URL}/operator/order/getlist?${data}`,
                });
                commit("SET_OPERATOR_ORDERS", {
                    ordersList: res.data.orders,
                });
            } catch (error) {
                alert(error)
            }

        },
        async actionChangeStatus({ commit }, data) {
            try {
                await axios({
                    url: `${BASE_URL}/operator/order/changeStatus?status_id=${data.status_id}&order_id=${data.order_id}`,
                    data: data,
                    method: "POST",
                });
                commit("smsResult", {
                  
                });
            } catch (error) {
               console.log(error)
            }

        },
        async actionSendSmsOperator(_, data) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/operator/order/sms`,
                    data: data,
                    method: "POST",
                });
             return res;
            } catch (error) {
               console.log(error)
            }

        },
    },



    //   getters: {
    //     isLoggedIn: (state) => !!state.token,
    //     authStatus: (state) => state.status,
    //   },
};
