import axios from "axios";
import {BASE_URL} from './../consts';
export default {
    state: {
        days: [],
        adminOrders:[],
        dayOfWeek: 0,
        groupItems:{
            groupList:{
                products:{}
            }
        },
        catGroup: 1,
        productParam:{
            product:{
                files:[{
                    thumb_path:''
                }]
            }
        },
        productParamDay:[],
        cells:[]
    },
    mutations: {
        SET_DAYS(state, payload) {
            state.days = payload.days;
        },

        SET_ADMIN_OREDES(state, payload) {
            state.adminOrders = payload.adminOrders.orders;
        },
        SET_DAY_OF_WEEK(state, payload) {
            state.dayOfWeek = payload;
        },
        SET_GROUP_ITEMS(state, payload) {
            state.groupItems = payload;
        },
        SET_CAT_GROUP(state, payload) {
            state.catGroup = payload;
        },
        SET_PRODUCT_PARAM(state, payload){
            state.productParam = payload.productParam;
        },
        SET_PRODUCT_PARAM_DAY(state, payload){
            state.productParamDay = payload.productParamDay.data;
        },
        SET_CELLS(state, payload){
state.cells = payload.cells
        }
    },

    actions: {
        async actionAddDayOff(_,date) {
            try {
                await axios({
                    url: `${BASE_URL}/admin/dayofweek/add`,
                    method: "POST",
                    data: date
                });
             
            } catch (error) {
                alert(error)
            }

        },
        async actionDeleteDayOff(_,date) {
            try {
                await axios({
                    url: `${BASE_URL}/admin/dayofweek/delete`,
                    method: "POST",
                    data: date
                });
             
            } catch (error) {
                alert(error)
            }

        },

        
        async actionGetDayOfWeek({ commit }) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/dayofweek/getlist`,
                });
                commit("SET_DAYS", {
                    days: res.data,
                });
            } catch (error) {
                alert(error)
            }

        },

        async actionGetAdminOrders({ commit },data) {
            if(data.date==0){
                delete data.date
            }
            try {
                const res = await axios({
                    url: `${BASE_URL}/admin/order/getList`,
                    method: "POST",
                    data: data
                });
                commit("SET_ADMIN_OREDES", {
                    adminOrders: res.data,
                });
            } catch (error) {
                alert(error)
            }

        },
        async actionGetCells({ commit }) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/admin/cells/get`,
                });
                commit("SET_CELLS", {
                    cells: res.data.cells,
                });
            } catch (error) {
                alert(error)
            }

        },
        async actionGetParamsProducts({ commit },id) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/admin/product/${id}/static/get`,
                });
                commit("SET_PRODUCT_PARAM", {
                    productParam: res.data,
                });
            } catch (error) {
                alert(error)
            }

        },
        async actionUpdateParamsProducts(_,data) {
            try {
               await axios({
                    url: `${BASE_URL}/admin/product/${data.id}/static/update`,
                    method: "POST",
                    data:data
                });
            } catch (error) {
                alert(error)
            }

        },
        async actionGetParamOnDay({ commit },data) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/admin/product/${data.id}/dynamic/get?date=${data.date}`,
                });
                commit("SET_PRODUCT_PARAM_DAY", {
                    productParamDay: res.data,
                });
            } catch (error) {
               // alert(error.response.data.message)
            }

        },
        async actionUpdateParamOnDay(_,data) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/admin/product/${data.id}/dynamic/update`,
                    method: "POST",
                    data:data
                });
                // commit("SET_GROUP_LIST", {
                //     groupList: res.data,
                // });
                return res;
            } catch (error) {
               return error.response.data;
            }

        },
        async actionUpdatePhoto(_,data) {
            console.log(data.image)
    
            axios.post(  `${BASE_URL}/admin/product/${data.id}/updatePhoto`,
            data.image,
            {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function(){
      console.log('SUCCESS!!');
    })
    .catch(function(){
      console.log('FAILURE!!');
    });
            // try {
            //     const res = await axios({
            //         url: `${BASE_URL}/admin/product/${data.id}/updatePhoto`,
            //         method: "POST",
            //         data:data
            //     });
            //     return res;
            // } catch (error) {
            //     alert(error.response.data.message)
            // }

        },
        async actionGetGroupAdmin({ commit },data) {
            var url;
            if(data.date){
                url = `${BASE_URL}/admin/group/${data.id}?date=${data.date}`
            } else{
                 url = `${BASE_URL}/admin/group/${data.id}`
            }
            try {
                const res = await axios({
                    url: url
              
                });
                commit("SET_GROUP_ITEMS", {
                    groupList: res.data,
                });
            } catch (error) {
                commit("SET_GROUP_ITEMS", {
                    groupList: [],
                });
               // alert(error.response.data.message)
            }

        },
        async actionGenerateCells(_,data) {
            try {
                const res = await axios({
                    url: `${BASE_URL}/admin/cells/setByDate`,
                    method: "POST",
                    data:data
                });
                return res
            } catch (error) {
                alert(error.response.data.message)
            }

        },
    },



    //   getters: {
    //     isLoggedIn: (state) => !!state.token,
    //     authStatus: (state) => state.status,
    //   },
};
